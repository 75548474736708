import { createSlice } from "@reduxjs/toolkit";

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    loading: false,
    data: "",
  },
  reducers: {
    fetchStartDashboard: (state) => ({
      ...state,
      loading: true,
    }),
    fetchSuccessDashboard: (state) => ({
      ...state,
      loading: false,
    }),
    dashboardData: (state, action) => ({
      ...state,
      data: action.payload,
    }),
  },
});

export const { dashboardData, fetchStartDashboard, fetchSuccessDashboard } =
dashboardSlice.actions;

export default dashboardSlice.reducer;
