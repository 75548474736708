let cussrentUserID = JSON.parse(localStorage.getItem("TOKEN"))?.user?.id;
export const unAuthenticatedRoutes = {
  LOGIN: "/login",
  SIGN_UP: "/signup/:userType",
  RESET_PASSWORD: "/reset-password/:id/:resetToken",
  SET_PASSWORD: "/set-password/:id/:resetToken",
  FORGET_PASSWORD: "/forget-password",
  EMAIL_SENT: "/email-sent",
  VERIFY_EMAIL: "/verify-email",
  EMAIL_VERIFICATION: "/email-verification/:token",
  PASSWORD_CHANDED: "/password-changed",
  THANKYOU: "/thankyou",
};

export const authenticatedRoutes = {
  //common routes
  ADMIN_DASHBOARD: "/admin/dashboard",
  ADMIN_OFFERED_COURSES: "/admin/offered-courses",
  ADMIN_PAYMENT: "/admin/payment",
  ADMIN_TEACHER_EARNING: "/admin/teacher-earning",
  ADMIN_TEACHER_DETAIL: "/admin/teacher-earning/detail/:id",
  TEACHER_PAYMENT: "/teacher/payment",
  TEACHER_PAYMENT_DETAIL: "/teacher/payment/detail/:id",
  ADMIN_NOTIFICATIONS: "/admin/notifications",
  ADMIN_STUDENT_PROFILE: "/admin/student-profile/:id",
  ADMIN_PARENT_PROFILE: "/admin/parent-profile/:id",
  ADMIN_THANKYOU: "/admin/thank-you",
  ADMIN_NOTIFICATION_LISTING: "/admin/notification-listing",
  ADMIN_NOTIFICATION_ROOM: "/admin/notification-broadcast/:roomId",
  COURSE_CATEGORY: "/course-category",
  COURSE_TYPE: "/course-type",
  MERGED_CLASS: "/merged-class",
  MERGED_CLASS_DETAIL: "/merged-class-detail/class/:class_id",
  ADMIN_MERGED_CLASS_DETAIL: "/admin/merged-class-detail/class/:class_id",
  DASHBOARD: "/dashboard",
  OFFERED_COURSES: "/offered-courses",
  STUDENT: "/student",
  PARENTS: "/parents",
  TEACHERS: "/teachers",
  PAYMENT: "/payment",
  ADMIN_COURSE_DETAIL: "/admin/course-detail/:id",
  COURSE_DETAIL: "/course-detail/:id",
  USER_PROFILE: "/profile",
  CHAT: "/chat",
  COURSE_REQUEST: "/course-request",
  ADMIN_CLASS_DETAIL: "/admin/class-detail/course/:course_id/class/:class_id",
  CLASS_DETAIL: "/class-detail/course/:course_id/class/:class_id",
  CLASS_RECORDING: "/recording",
  ADMIN_TEACHER_LISTING: "/admin/teacher-listing",
  ADMIN_TEACHER_PROFILE: "/admin/teacher-profile/:id",
  TEACHER_OFFERED_COURSES: "/teacher/offered-courses",
  MERGE_CLASS: "/merge-class",
};

export const permissionGroups = {
  // AdminDashboard: "AdminDashboard",
  // AdminUserManagement: "AdminUserManagement",
  // AdminStateAudits: "AdminStateAudits",
  // AdminActivityLogs: "AdminActivityLogs",
  // AdminDocumentManagement: "AdminDocumentManagement",
  // UserManagement: "UserManagement",
  // AssignTaskManagement: "AssignTaskManagement",
  // AddCalendar: "AddCalendar",
  // AppointmentManagement: "AppointmentManagement",
  // RoleManagement: "RoleManagement",
  // SendingEmails: "SendingEmails",
  // InvoiceManagement: "InvoiceManagement",
};

export const permissionsConstants = {
  // UserListing: "User listing",
  // UserCreate: "User create",
  // UserDetails: "User details",
  // UserUpdate: "User update",
  // UserDelete: "User delete",
  // UserRelationListing: "User relation listing",
  // UserRelationCreate: "User relation create",
  // UserRelationDetails: "User relation details",
  // UserRelationUpdate: "User relation update",
  // UserRelationDelete: "User relation delete",
  // UserDocumentListing: "User document listing",
  // UserDocumentCreate: "User document create",
  // UserDocumentDetails: "User document details",
  // UserDocumentUpdate: "User document update",
  // UserDocumentDelete: "User document delete",
  // roleListing: "role listing",
  // roleCreate: "role create",
  // roleDetails: "role details",
  // roleUpdate: "role update",
  // roleDelete: "role delete",
  // permissionListing: "permission listing",
  // permissionDetails: "permission details",
  // permissionUpdate: "permission update",
  // permissionDelete: "permission delete",
  // RolePermissionUpdate: "Role permission update",
  // documentListing: "document listing",
  // documentCreate: "document create",
  // documentDetails: "document details",
  // documentUpdate: "document update",
  // documentDelete: "document delete",
  // notesListing: "notes listing",
  // notesCreate: "notes create",
  // notesDetails: "notes details",
  // notesUpdate: "notes update",
  // notesDelete: "notes delete",
  // notesCommentListing: "notes comment listing",
  // notesCommentCreate: "notes comment create",
  // notesCommentDetails: "notes comment details",
  // notesCommentUpdate: "notes comment update",
  // notesCommentDelete: "notes comment delete",
  // SendingUpdateEmais: "Sending/Update Emais",
  // invoiceListing: "invoice listing",
  // invoiceCreate: "invoice create",
  // invoiceDetails: "invoice details",
  // invoiceUpdate: "invoice update",
  // invoiceDelete: "invoice delete",
  // assignTaskListing: "assign task listing",
  // assignTaskCreate: "assign task create",
  // assignTaskDetails: "assign task details",
  // assignTaskUpdate: "assign task update",
  // assignTaskDelete: "assign task delete",
  // assignDocumentsListing: "assign documents listing",
  // assignDocumentsCreate: "assign documents create",
  // assignDocumentsDetails: "assign documents details",
  // assignDocumentsUpdate: "assign documents update",
  // assignDocumentsDelete: "assign documents delete",
  // TaskCommentListing: "Task comment listing",
  // TaskCommentCreate: "Task comment create",
  // TaskCommentUpdate: "Task comment update",
  // TaskCommentDelete: "Task comment delete",
  // appointmentListing: "appointment listing",
  // appointmentCreate: "appointment create",
  // appointmentDetails: "appointment details",
  // appointmentUpdate: "appointment update",
  // appointmentDelete: "appointment delete",
};

export const ROLE_TYPES = {
  superAdmin: "super-admin",
  teacher: "teacher",
  student: "Student",
  parent: "student",
};

/*
add assigned form in user role (done)
and that kind of another things (done)
add condition in state audits (faizan remaining work)
push admin dashboard if role type is system coordinator (done)
remove sub menu things conditionaly like contact add / assigned task /
contact profile conditions (done)



condition in my contact & new contact &
email compose / sent / draft
*/

export const AllMenusItems = {
  [ROLE_TYPES.superAdmin]: {
    AdminDashboard: {
      text: "Dashboard",
      linkTo: authenticatedRoutes.ADMIN_DASHBOARD,
      selectedOptionKey: "dashboard",
      icon: "tod-dashboard",
    },
    OfferedCourses: {
      text: "Offered Courses",
      linkTo: authenticatedRoutes.ADMIN_OFFERED_COURSES,
      selectedOptionKey: "offered-courses",
      icon: "tod-courses",
    },
    CourseCategory: {
      text: "Course Category",
      linkTo: authenticatedRoutes.COURSE_CATEGORY,
      selectedOptionKey: "course-category",
      icon: "tod-courses",
    },
    CourseType: {
      text: "Course Type",
      linkTo: authenticatedRoutes.COURSE_TYPE,
      selectedOptionKey: "course-type",
      icon: "tod-courses",
    },
    MergedClass: {
      text: "Calendar",
      linkTo: authenticatedRoutes.MERGED_CLASS,
      selectedOptionKey: "merged-class",
      icon: "tod-courses",
    },
    Student: {
      text: "Student",
      linkTo: authenticatedRoutes.STUDENT,
      selectedOptionKey: "student",
      icon: "tod-students",
    },
    Parents: {
      text: "Parents",
      linkTo: authenticatedRoutes.PARENTS,
      selectedOptionKey: "parents",
      icon: "tod-parents",
    },
    TEACHERS: {
      text: "Teachers",
      linkTo: authenticatedRoutes.TEACHERS,
      selectedOptionKey: "teachers",
      icon: "tod-students",
    },
    Payment: {
      text: "Payment",
      linkTo: authenticatedRoutes.ADMIN_PAYMENT,
      selectedOptionKey: "payment",
      icon: "tod-payment",
    },

    TeacherEarning: {
      text: "Teacher Earnings",
      linkTo: authenticatedRoutes.ADMIN_TEACHER_EARNING,
      selectedOptionKey: "teacher-earning",
      icon: "tod-payment",
    },
    // Notifications: {
    //   text: "General Notifications",
    //   linkTo: authenticatedRoutes.ADMIN_NOTIFICATIONS,
    //   selectedOptionKey: "notifications",
    //   icon: "tod-notification-bell",
    // },

    Notifications: {
      text: "General Notifications",
      linkTo: authenticatedRoutes.ADMIN_NOTIFICATIONS,
      selectedOptionKey: "notifications",
      icon: "tod-notification-bell",
      subMenu: true,
      subMenuItems: [
        {
          text: "New Notifcation",
          linkTo: authenticatedRoutes.ADMIN_NOTIFICATIONS,
          selectedOptionKey: "notifications",
        },
        {
          text: "Notification List",
          linkTo: authenticatedRoutes.ADMIN_NOTIFICATION_LISTING,
          selectedOptionKey: "notification-listing",
        },
      ],
    },
    Profile: {
      text: "Profile",
      linkTo: authenticatedRoutes.USER_PROFILE.replace(":id", cussrentUserID),
      selectedOptionKey: "profile",
      icon: "tod-courses",
    },
  },
  [ROLE_TYPES.student]: {
    Profile: {
      text: "Profile",
      linkTo: authenticatedRoutes.USER_PROFILE.replace(":id", cussrentUserID),
      selectedOptionKey: "profile",
      icon: "tod-courses",
    },
    OfferedCourses: {
      text: "Offered Courses",
      linkTo: authenticatedRoutes.OFFERED_COURSES,
      selectedOptionKey: "offered-courses",
      icon: "tod-courses",
    },
    CourseRequest: {
      text: "Course Request",
      linkTo: authenticatedRoutes.COURSE_REQUEST,
      selectedOptionKey: "course-request",
      icon: "tod-courses",
    },
    Payment: {
      text: "Payment",
      linkTo: authenticatedRoutes.PAYMENT,
      selectedOptionKey: "payment",
      icon: "tod-payment",
    },
    MergedClass: {
      text: "Merged Class",
      linkTo: authenticatedRoutes.MERGED_CLASS,
      selectedOptionKey: "merged-class",
      icon: "tod-courses",
    },
  },
  [ROLE_TYPES.teacher]: {
    OfferedCourses: {
      text: "Offered Courses",
      linkTo: authenticatedRoutes.TEACHER_OFFERED_COURSES,
      selectedOptionKey: "offered-courses",
      icon: "tod-courses",
    },

    TeacherEarning: {
      text: "Earningss",
      linkTo: authenticatedRoutes.TEACHER_PAYMENT,
      selectedOptionKey: "payment",
      icon: "tod-payment",
    },
    MergedClass: {
      text: "Calendar",
      linkTo: authenticatedRoutes.MERGED_CLASS,
      selectedOptionKey: "merged-class",
      icon: "tod-courses",
    },
    Notifications: {
      text: "General Notifications",
      linkTo: authenticatedRoutes.ADMIN_NOTIFICATIONS,
      selectedOptionKey: "notifications",
      icon: "tod-notification-bell",
      subMenu: true,
      subMenuItems: [
        {
          text: "New Notifcation",
          linkTo: authenticatedRoutes.ADMIN_NOTIFICATIONS,
          selectedOptionKey: "notifications",
        },
        {
          text: "Notification List",
          linkTo: authenticatedRoutes.ADMIN_NOTIFICATION_LISTING,
          selectedOptionKey: "notification-listing",
        },
      ],
    },
    Profile: {
      text: "Profile",
      linkTo: authenticatedRoutes.USER_PROFILE.replace(":id", cussrentUserID),
      selectedOptionKey: "profile",
      icon: "tod-courses",
    },
  },
};
