import io from "socket.io-client";
import React, { useEffect, createContext, useState } from "react";
import AuthService from "../util/auth.service";
import notificationService from "../util/notification.service";
import { emitEventTypes, listenEventTypes } from "./EventTypes";
import { contextDefaultState } from "./DefaultState";

export const GlobalState = React.createContext(contextDefaultState);

const pageOptions = {
  page: 0,
  // limit: 10,
  limit: 200,
  totalItems: 1,
  totalPages: 1,
};

const GlobalProvider = ({ children }) => {
  const [rooms, setRooms] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isActiveChat, setIsActiveChat] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [socket, setSocket] = useState(null);
  const [roomLoader, setRoomLoader] = useState(false);
  const [messageLoader, setMessageLoader] = useState(false);
  const [roomsMeta, setRoomsMeta] = useState({ page: 0, limit: 15 });
  const [messagesMeta, setMessagesMeta] = useState(pageOptions);
  const [isReceiveNewMessage, setIsReceiveNewMessage] = useState(false);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [countNum, setCountNum] = useState(0);

  //// Socket Section Begin

  // useEffect(() => {
  //   console.log("selectedRoom", selectedRoom);
  // }, [selectedRoom]);

  // console.log("selectedRoom outside", selectedRoom);

  useEffect(() => {
    // Socket Connection
    const token = AuthService.getToken();
    const socketInitial = io(process.env.REACT_APP_SOCKET_URL, {
      auth: {
        token: token,
      },
      // transports: ["websocket", "polling", "flashsocket"],
      transports: ["websocket"],
    });

    setSocket(socketInitial);

    // Socket Listener
    // ===============
    socketInitial.on(listenEventTypes.ON_CONNECT, () => {});
    socketInitial.on(listenEventTypes.ON_DISCONNECT, () => {});
    socketInitial.on("on_get_selected_room", (room) => {
      setSelectedRoom(room);
      // onRoomChange(room)
    });

    socketInitial.on(listenEventTypes.RECEIVE_ERROR, ({ message }) => {
      notificationService.error(message);
    });

    socketInitial.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message} ${err}`);
    });

    socketInitial.on(listenEventTypes.ROOM_LISTING, (data) => {
      if (Array.isArray(data)) {
        setRooms(data);
        setRoomsMeta({ page: 0, limit: 15 });
      } else if (data) {
        // console.log("room listing......", data);
        setRoomsMeta(data?.meta);
        setRooms((prev) => {
          if (data?.meta?.page === 1) {
            return [...data?.items];
          } else {
            return [...prev, ...data?.items];
          }
        });
      }
      setRoomLoader(false);
    });
    socketInitial.on(listenEventTypes.MESSAGE_LISTING, (data) => {
      if (Array.isArray(data)) {
        setMessages((prev) => [...prev, ...data]);
        setMessagesMeta(pageOptions);
      } else if (data) {
        if (Array.isArray(data?.items) && data?.meta) {
          setMessages((prev) => [...prev, ...data?.items]);
          setMessagesMeta(data?.meta);
        }
      }
      setMessageLoader(false);
    });

    socketInitial.on(listenEventTypes.RECEIVE_NEW_MESSAGE, (data) => {
      let isActiveChat = false;
      setSelectedRoom((prevState) => {
        isActiveChat = parseInt(data?.room?.id) === prevState?.id;
        return prevState;
      });
      setIsActiveChat(isActiveChat);
      setIsReceiveNewMessage(true);

      if (isActiveChat) {
        setMessages((prevMessages) => [...prevMessages, data]);
      }
    });

    socketInitial.on(listenEventTypes.ROOM_BY_ID, (data) => {
      setSelectedRoom(data);
    });

    socketInitial.on(listenEventTypes.ON_AUTH_FAILED, (data) => {});
    socketInitial.on(listenEventTypes.RECEIVE_NOTIFICATION, (data) => {
      setIsNewNotification(true);
    });
  }, []);
  //// Socket Section End

  const emitEvent = (event, data) => {
    setLoader(event);
    socket.emit(event, data);
  };

  const setLoader = (event) => {
    switch (event) {
      case emitEventTypes.REQUEST_PAGINATED_ROOM_LISTING:
        setRoomLoader(true);
        break;

      case emitEventTypes.REQUEST_MESSAGE_LISTING:
        setMessageLoader(true);
        break;
    }
  };

  const onRoomChange = (index) => {
    if (Array.isArray(rooms)) {
      const room = rooms[index];
      if (room?.id !== selectedRoom?.id) {
        setMessagesMeta(pageOptions);
        setMessages([]);
      }
    }
  };

  const resetMeta = () => {
    setMessagesMeta(pageOptions);
    setMessages([]);
    setSelectedRoom(null);
  };

  return (
    <GlobalState.Provider
      value={{
        isActiveChat,
        setIsActiveChat,
        setIsReceiveNewMessage,
        isReceiveNewMessage,
        selectedRoom: selectedRoom,
        rooms,
        emitEvent,
        setSelectedRoom: setSelectedRoom,
        messages,
        roomLoader,
        messageLoader,
        roomsMeta,
        messagesMeta,
        resetMeta,
        onRoomChange,
        isNewNotification,
        setIsNewNotification,
        socket,
        countNum, 
        setCountNum,
      }}
    >
      {children}
    </GlobalState.Provider>
  );
};
export default GlobalProvider;
