import React, { memo } from "react";
import { Spin } from "antd";

const FullPageLoader = () => {
  return (
    <React.Fragment>
      <div className="loader loading">
        <img alt="logo1" src={require("assets/images/Tutor_Of_Dragons_Logo.png")} />
        <Spin /> 
        Please wait ...
      </div>
    </React.Fragment>
  );
};

export default memo(FullPageLoader);
