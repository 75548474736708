const paginationConstant = {
  PAGE_SIZE: 10,
};

const pageProperties = {
  WIDTH: 1024,
  OVERFLOW_Y: 250,
};

export const STORAGE_CONST = {
  USER_INFO: "TOKEN",
};

export const USA_ID = 233;

export const US_STATE_COUNT = 52;

export { paginationConstant, pageProperties };
