import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import SettingsReducer from "../slices/Settings";
import CommonReducer from "../slices/Common";
import userRelationSlice from "../slices/UserRelation";
import dashboardSlice from "../slices/Dashboard";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: SettingsReducer,
    common: CommonReducer,
    relation: userRelationSlice,
    dashboard: dashboardSlice,
  });

export default createRootReducer;
