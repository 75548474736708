import { createSlice } from "@reduxjs/toolkit";

const userRelationSlice = createSlice({
  name: "relation",
  initialState: {
    loading: false,
    data: "",
  },
  reducers: {
    fetchStartRelation: (state) => ({
      ...state,
      loading: true,
    }),
    fetchSuccessRelation: (state) => ({
      ...state,
      loading: false,
    }),
    userRelationData: (state, action) => ({
      ...state,
      data: action.payload,
    }),
  },
});

export const { userRelationData, fetchStartRelation, fetchSuccessRelation } =
  userRelationSlice.actions;

export default userRelationSlice.reducer;
