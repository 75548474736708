import { STORAGE_CONST } from "../constants/constant";
import LocalStorageService from "./local-storage.service";
import utilService from "./utils.service";

const AuthService = {
  isTokenExist,
  getToken,
  getUserInfo,
  getRole,
  getRoleObject,
  getCurrentUserId,
  getPermissionsGroups,
  getPermissionsNames,
};

function getRole() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo?.user?.role;
  }
  return null;
}

function getRoleObject() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo?.user?.role;
  }
  return null;
}

function getPermissionsGroups() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo?.user?.userPermissions?.map((singlePermission) => {
      return utilService.pascalCase(
        singlePermission?.permissions?.attributes?.group
      );
    });
  }
  return null;
}

function getPermissionsNames() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo?.user?.userPermissions?.map((singlePermission) => {
      return utilService.pascalCase(
        singlePermission?.permissions?.attributes?.name
      );
    });
  }
  return null;
}

function getCurrentUserId() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo?.user?.id;
  }
  return null;
}

function isTokenExist() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    return true;
  }
  return false;
}

function getToken() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    return userInfo.token;
  }
  return null;
}

function getUserInfo() {
  let userInfo = LocalStorageService.get(STORAGE_CONST.USER_INFO);
  if (userInfo) {
    console.log("userInfo",JSON.parse(userInfo));
    return JSON.parse(userInfo);
  }
  return null;
}

export default AuthService;
