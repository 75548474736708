import * as lodashIsEmpty from "lodash.isempty";
import get from "lodash.get";
import { unAuthenticatedRoutes, authenticatedRoutes } from "../routes/constant";
import notificationService from "./notification.service";
import moment from "moment";
import AuthService from "./auth.service";

function replaceNullWithPlaceholder(value, placeHolder = "--") {
  if (!value) {
    return placeHolder;
  }
  return value;
}

function getValue(...param) {
  return get(...param);
}

function redirectToLogin(loginUrl = utilService.loginUrl) {
  let returnUrl = encodeURIComponent(
    window.location.pathname.replace(/[//]+/, "") + window.location.search
  );
  utilService.redirectTo(loginUrl + "?returnUrl=" + returnUrl);
}

function redirectTo(url) {
  window.location.href = utilService.baseUrl + url;
}

function redirectToReturnUrl() {
  utilService.redirectTo(
    utilService.getUrlParameterByName("returnUrl")
      ? "/" + utilService.getUrlParameterByName("returnUrl")
      : utilService.defaultUrl
  );
}

function getUrlParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  const regexS = `[\\?&]${name}=([^&#]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(window.location.search);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

function isEmpty(value) {
  return lodashIsEmpty(value);
}

function numberFormat(number, n, x, s, c) {
  const re = `\\d(?=(\\d{${x || 3}})+${n > 0 ? "\\D" : "$"})`;
  const num = number.toFixed(Math.max(0, ~~n));

  return (c ? num.replace(".", c) : num).replace(
    new RegExp(re, "g"),
    `$&${s || ","}`
  );
}

function toNumber(input, decimal = 2) {
  if (input && !isNaN(input)) {
    return numberFormat(input, decimal, 3);
  }
  return input;
}

function getQueryParams(query, param) {
  let params = new URLSearchParams(query);
  return params.get(param);
}

function getUrlParam(urlParam, search) {
  return new URLSearchParams(urlParam).get(search);
}

function capitalizeFirstLetter(value) {
  if (!value) return null;

  return value.charAt(0).toUpperCase() + value.slice(1);
}

function apiPrintError(data) {
  if (data?.message) notificationService.warning(data?.message[0]);
}

function downloadFileFromUrl(url, fileName) {
  if (!url) return null;
  // const aTag = document.createElement("a");
  // aTag.href = url;
  // aTag.download = fileName.toUpperCase().replace(".pdf", "");
  // aTag.click();
  const ancorElement = document.createElement("a");
  ancorElement.setAttribute("href", url);
  ancorElement.setAttribute("target", "_blank");

  ancorElement.style.display = "none";
  document.body.appendChild(ancorElement);

  ancorElement.click();

  document.body.removeChild(ancorElement);
}
function convertCurrencyFormat(num) {
  return "$" + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}
function convertDateToOurFormat(date) {
  if (!date) {
    return "--";
  }
  const d = new Date(date);
  const customMoment = moment(d);
  return `${customMoment.format("l")} ${customMoment.format("LT")}`;
}
function convertDateToFormat(date, format) {
  const d = new Date(date);
  const customMoment = moment(d);
  return `${customMoment.format(format)}`;
}

// replace object key with value on matching url sub string
function createDynamicUrl(dynamicUrl, object) {
  for (const key in object) {
    dynamicUrl = dynamicUrl.replace(`{${key}}`, object[key]);
  }
  return dynamicUrl;
}
function arrayToUrl(baseURL, array = []) {
  if (Array.isArray(array)) {
    for (let index = 0; index < array.length; index++) {
      if (index === 0) {
        if (index === array.length - 1) {
          return (baseURL += `?roles=${array[index]}`);
        }
        baseURL += `?roles=${array[index]}&`;
      } else if (index === array.length - 1) {
        baseURL += `roles=${array[index]}`;
      } else {
        baseURL += `roles=${array[index]}&`;
      }
    }
  }
  return baseURL;
}
function stringLimit(str = "", limit = 10, replaceWith = "..") {
  if (str.length > 10) {
    return `${str.substring(0, limit)} ${replaceWith}`;
  }
  return str;
}
function disabledDate(current) {
  return current && current <= moment().startOf("day");
}
function disabledPastDate(current) {
  return current && current >= moment().startOf("day");
}

//String Cases

/**
 * Replaces all accented chars with regular ones
 */
function replaceAccents(str) {
  // verifies if the String has accents and replace them
  if (str.search(/[\xC0-\xFF]/g) > -1) {
    str = str
      .replace(/[\xC0-\xC5]/g, "A")
      .replace(/[\xC6]/g, "AE")
      .replace(/[\xC7]/g, "C")
      .replace(/[\xC8-\xCB]/g, "E")
      .replace(/[\xCC-\xCF]/g, "I")
      .replace(/[\xD0]/g, "D")
      .replace(/[\xD1]/g, "N")
      .replace(/[\xD2-\xD6\xD8]/g, "O")
      .replace(/[\xD9-\xDC]/g, "U")
      .replace(/[\xDD]/g, "Y")
      .replace(/[\xDE]/g, "P")
      .replace(/[\xE0-\xE5]/g, "a")
      .replace(/[\xE6]/g, "ae")
      .replace(/[\xE7]/g, "c")
      .replace(/[\xE8-\xEB]/g, "e")
      .replace(/[\xEC-\xEF]/g, "i")
      .replace(/[\xF1]/g, "n")
      .replace(/[\xF2-\xF6\xF8]/g, "o")
      .replace(/[\xF9-\xFC]/g, "u")
      .replace(/[\xFE]/g, "p")
      .replace(/[\xFD\xFF]/g, "y");
  }

  return str;
}

/**
 * Remove non-word chars.
 */
function removeNonWord(str) {
  return str.replace(/[^0-9a-zA-Z\xC0-\xFF \-]/g, "");
}

/**
 * Convert string to camelCase text.
 */
function camelCase(str) {
  str = replaceAccents(str);
  str = removeNonWord(str)
    .replace(/\-/g, " ") //convert all hyphens to spaces
    .replace(/\s[a-z]/g, upperCase) //convert first char of each word to UPPERCASE
    .replace(/\s+/g, "") //remove spaces
    .replace(/^[A-Z]/g, lowerCase); //convert first char to lowercase
  return str;
}

/**
 * camelCase + UPPERCASE first char
 */
function pascalCase(str) {
  return camelCase(str).replace(/^[a-z]/, upperCase);
}

/**
 * "Safer" String.toUpperCase()
 */
function upperCase(str) {
  return str.toUpperCase();
}

/**
 * "Safer" String.toLowerCase()
 */
function lowerCase(str) {
  return str.toLowerCase();
}

function checkPermissionOfUser(permissionName = false) {
  if (permissionName) {
    const userInfo = AuthService.getUserInfo();

    if (userInfo?.user?.role?.name == "Admin") {
      return true;
    }

    const permissionNames = userInfo?.user?.userPermissions?.map(
      (singlePermission) => {
        return utilService.pascalCase(
          singlePermission?.permissions?.attributes?.name
        );
      }
    );

    const pascalCasePermissionName = utilService.pascalCase(permissionName);
    if (permissionNames.includes(pascalCasePermissionName)) {
      return true;
    }
  }

  return false;
}

const utilService = {
  loginUrl: unAuthenticatedRoutes.LOGIN,
  defaultUrl: authenticatedRoutes.DASHBOARD,
  baseUrl: process.env.REACT_APP_BASE_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  toNumber,
  getValue,
  redirectToLogin,
  redirectTo,
  redirectToReturnUrl,
  getUrlParameterByName,
  isEmpty,
  getQueryParams,
  getUrlParam,
  replaceNullWithPlaceholder,
  capitalizeFirstLetter,
  apiPrintError,
  downloadFileFromUrl,
  convertDateToOurFormat,
  createDynamicUrl,
  convertDateToFormat,
  stringLimit,
  disabledDate,
  disabledPastDate,
  arrayToUrl,
  replaceAccents,
  removeNonWord,
  camelCase,
  pascalCase,
  upperCase,
  lowerCase,
  checkPermissionOfUser,
  convertCurrencyFormat,
};

export default utilService;
