import { number } from "prop-types";

/**
 * @typedef {{totalPages: number, totalItems: number, page: number, limit: number}} Meta
 */

/**
 *
 * @typedef {{id: number, name: string, email: string}} User
 */

/**
 * @typedef {{id: number, name: string,users: User[]}} Room
 */

/**
 * @typedef {{id: number, text: string, created_at: Date, updated_at: Date}} Message
 */

export const contextDefaultState = {
  /**
   * @type boolean
   */
  isActiveChat: false,
  /**
   * @type boolean
   */
  isReceiveNewMessage: false,
  /**
   * @function
   * @name setIsActiveChat
   * @param {boolean} state
   * @returns {null}
   */
  setIsActiveChat: (state) => null,
  /**
   * @function
   * @name setIsReceiveNewMessage
   * @param {boolean} state
   * @returns {null}
   */
  setIsReceiveNewMessage: (state) => null,

  /**
   * @type boolean
   */
  isNewNotification: false,
  /**
   * @function
   * @name setIsNewNotification
   * @param {boolean} state
   * @returns {null}
   */
  setIsNewNotification: (state) => null,
  /**
   * @type {Room} - selectedRoom
   */
  selectedRoom: {},
  /**
   * @type {Message[]} - messages
   */
  messages: [],

  /**
   * @type boolean
   */
  roomLoader: false,

  /**
   * @type boolean
   */
  messageLoader: false,

  /**
   * @function
   * @name emitEvent
   * @param {string} event
   * @param {Object | string} payload
   * @returns {null}
   */
  emitEvent: (event, payload) => null,

  /**
   * @type {Array.<Room>} - rooms
   */
  rooms: [],

  /**
   * @function
   * @name setSelectedRoom
   * @param {Room} room
   * @return null
   */
  setSelectedRoom: (room) => null,

  /**
   * @type {Meta}
   */
  roomsMeta: {},

  /**
   * @type {Meta}
   */
  messagesMeta: {},

  /**
   * @function
   * @name onRoomChange
   * @return null
   */
  onRoomChange: () => null,
};
