export const listenEventTypes = {
  ON_CONNECT: "connect",
  ON_DISCONNECT: "disconnect",
  ON_AUTH_FAILED: "auth failed",
  ROOM_LISTING: "rooms",
  JOIN_USER_LISTING: "joinedUser",
  MESSAGE_LISTING: "messages",
  RECEIVE_NEW_MESSAGE: "messageAdded",
  RECEIVE_ERROR: "on_error",
  RECEIVE_NOTIFICATION: "notification",
  ROOM_BY_ID: "room",
};

export const emitEventTypes = {
  REQUEST_MESSAGE_LISTING: "messageListing",
  REQUEST_SEARCH_ROOMS: "getSearchRecord",
  REQUEST_LEAVE_ROOM: "leaveRoom",
  REQUEST_PAGINATED_ROOM_LISTING: "paginateRooms",
  REQUEST_JOIN_USER_LISTING: "retrieveJoinedUsers",
  REQUEST_JOIN_ROOM: "joinRoom",
  CREATE_ROOMS: "createRoom",
  CREATE_MESSAGE: "addMessage",
  REMOVE_ROOM: "removeRoom",
  GET_ROOM_BY_ID: "getRoomById",
};
